import "./parents_functionalities.css";
import { Button } from "../general";
import VideoContainer from "../schools/VideoContainer";
import { useTranslation } from "react-i18next";
const ParentsFunctionalities = () => {
  const { i18n, t } = useTranslation("common");

  return (
    <section className="parents_functionalities">
      <h1>{t("parents.functionalities.title")}</h1>
      <div className="parents_video_container">
        <VideoContainer type={"parent"} />
      </div>
      <div className="btns">
        <Button
          text={t("parents.functionalities.button1")}
          type={"primary"}
          linkType={"intern"}
          link={`/${i18n.language}/p/register`}
        />
        <Button
          text={t("parents.functionalities.button2")}
          type={"ghost"}
          linkType={"intern"}
          link={`/${i18n.language}/p/subscriptions?user=parent`}
        />
      </div>
    </section>
  );
};
export default ParentsFunctionalities;
