import { Button2 } from "../general";
import { useTranslation, Trans } from "react-i18next";

import "./intro.css";
import { IntroLogos } from "./introLogos";
import robotIcon from "../../assets/home/intro/robot.svg"
const Intro = ({ introRef, calculatorRef }) => {

  const { t } = useTranslation("common");

  const scrollToCalculator = () => {
    if (calculatorRef.current) {
      const calculatorTopOffset = calculatorRef.current.offsetTop;
      const windowHeight = window.innerHeight;
      const calculatorHeight = calculatorRef.current.offsetHeight;

      const targetScrollPosition = calculatorTopOffset - windowHeight / 2 + calculatorHeight / 2;

      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    }
  };



  return (
    <>
      <section className="intro">
        <div className="container_column">
          <div className="container_column_info">
            <div className="container_column_info_text">
              <h1>
                <Trans i18nKey="home.intro.content.header" t={t} components={[<br />]} />
              </h1>
              <p>
                {t("home.intro.content.subTitle")}
              </p>
            </div>
            <Button2
              className="primary solid"
              handleClick={scrollToCalculator}
            >
              {t("home.intro.content.buttons.cta")}
            </Button2>
          </div>
          <IntroLogos id={"intro_logos_inside"} />
        </div>
        <div className="ai_section">
          <img src={robotIcon} alt={"robotIcon"} />
          <p>{t('home.intro.content.ai_badge')}</p>
        </div>
      </section>
      <IntroLogos id={"intro_logos_outside"} />
    </>
  );
};

export default Intro;
