import React from 'react';

import { useNavigate } from 'react-router-dom';
import './button.css';
import './button2.css';

export const Button = ({ text, type, link, linkType, action, classList, disabled }) => {
  const navigate = useNavigate();

  const intenalLink = (path) => {
    navigate(path);
  };

  const externalLink = (url) => {
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  };

  return (
    <button
      className={'btn ' + (classList ? type + ' ' + classList : type)}
      onClick={(ev) => {
        if (action) {
          ev.preventDefault();
          action();
        } else {
          ev.preventDefault();

          if (link) {
            if (linkType === 'intern') {
              intenalLink(link);
            } else if (linkType === 'location') {
              window.location.href = link;
            } else {
              externalLink(link);
            }
          }
        }
      }}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export const Button2 = ({
  children,
  className,
  handleClick,
  leftIcon,
  leftIconHover,
  allowDefault = false,
}) => {
  const [icon, setIcon] = React.useState(leftIcon);

  const onClick = (ev) => {
    if (!allowDefault) ev.preventDefault();
    handleClick();
  };

  return (
    <button
      onClick={onClick}
      className={'btn2 ' + className}
      onMouseEnter={() => setIcon(leftIconHover ?? leftIcon)}
      onMouseLeave={() => setIcon(leftIcon)}
    >
      {icon && <img src={icon} alt="icon" className="left_icon" />}
      {children}
    </button>
  );
};
