import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Api2 from '../../../../Libs/api';

import PhoneInput from '../../../forms/PhoneInputWithSelect';
import Button from '../../../forms/SimpleButton/Button';
import Select from '../../../content/select/Select';
import checked from '../../../../assets/contact/checked-white.svg';

import './Form.css';

const recaptchaKey = '6LeAXfIpAAAAAIamiHU6M7gXuwmqTq5nIZFTHtz9';

const Form = () => {
  const { t, i18n } = useTranslation('common');

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const searchInputValueRef = useRef('');
  const searchInputTimeoutRef = useRef(undefined);

  const [schools, setSchools] = useState([]);
  const [searchIsLoading, setSearchIsLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    nume: '',
    telefon: '',
    email: '',
    mesaj: '',
  });

  const [selectValue, setSelectValue] = useState(undefined);
  const [errors, setErrors] = useState([]);

  const onInputChange = (value, input) => {
    setData({ ...data, [input]: value });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
    document.body.appendChild(script);
  }, []);

  const fetchSchools = (searchValue) =>
    new Promise((resolve) => {
      Api2.get(`/scoli/public?search=${searchValue}`)
        .then((response) => {
          const apiSchools = [];
          response.data.forEach((school) => {
            if (school && school.scoalaNume && school.scoalaID) {
              apiSchools.push({
                label: school.scoalaNume,
                value: school.scoalaID,
              });
            }
          });
          if (searchValue === searchInputValueRef.current) {
            setSchools(apiSchools);
          }
        })
        .finally(() => {
          resolve();
        });
    });

  const handleSearchCallback = (value) => {
    setSearchIsLoading(true);
    searchInputValueRef.current = value;

    if (value.length < 4) {
      clearTimeout(searchInputTimeoutRef.current);
      searchInputTimeoutRef.current = undefined;
      setSearchIsLoading(false);

      return;
    }
    if (searchInputTimeoutRef.current) {
      setSearchIsLoading(false);

      return;
    }
    searchInputTimeoutRef.current = setTimeout(async () => {
      setSearchIsLoading(true);

      searchInputTimeoutRef.current = undefined;

      await fetchSchools(searchInputValueRef.current);

      setSearchIsLoading(false);
    }, 1000);
  };

  const prepareRecaptcha = async () =>
    new Promise((resolve, reject) => {
      try {
        window.grecaptcha
          .execute(recaptchaKey, {
            action: 'submit',
          })
          .then((token) => {
            resolve(token);
          })
          .catch(() => {
            setShowErrorMessage(true);
            reject();
          });
      } catch (error) {
        setShowErrorMessage(true);
        reject();
      }
    });
  const apiRequest = async (token) => {
    return new Promise((resolve, reject) => {
      Api2.post('/generic/contact', {
        nume: data.nume,
        telefon: data.telefon.replace(/\s+/g, ''),
        email: data.email,
        mesaj: data.mesaj,
        scoalaID: data.scoalaID,
        recaptcha: token,
        _lang: i18n.language,
      })
        .then(() => {
          setSchools([]);
          setShowSuccessMessage(true);
          setData({
            nume: '',
            telefon: undefined,
            email: '',
            mesaj: '',
          });
          setSelectValue(undefined);
          resolve();
        })
        .catch((error) => {
          // Too many requests error
          if (error && error.response && error.response.status === 429) {
            setErrors([{ errorCode: '429', errorField: 'general' }]);
          } else if (error && error.response && typeof error.response.data !== 'string') {
            const apiErrors = error.response.data;
            setErrors(apiErrors);
          } else {
            setShowErrorMessage(true);
          }
          reject();
        });
    });
  };
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setErrors([]);
      const recaptchaToken = await prepareRecaptcha();
      await apiRequest(recaptchaToken);
    } catch (error) {
      setShowErrorMessage(true);
    } finally {
      setIsLoading(false);
    }
  };

  const hasError = (formErrors, fieldName, errorCode) => {
    if (!formErrors.length) return false;
    const error = formErrors.filter(
      (fieldErr) => fieldErr.errorField === fieldName && fieldErr.errorCode === errorCode
    );
    return error.length ? error[0] : false;
  };

  return (
    <section className="contact-form">
      {showSuccessMessage ? (
        <div className="contact-sent" onClick={() => setShowSuccessMessage(false)}>
          <img src={checked} alt={checked} />
          <div>
            <h1>{t('contact.form.sent-title')}</h1>
            <p>{t('contact.form.sent-subTitle')}</p>
          </div>
        </div>
      ) : (
        <>
          <h1>{t('contact.form.title')}</h1>
          <p>{t('contact.form.subTitle')}</p>
        </>
      )}
      <form className="form-container" onSubmit={submitForm}>
        <div className="form__input-wrapper">
          <p className="form__input-label">{t('contact.form.name')}</p>
          <input
            name="nume"
            type="text"
            value={data?.nume}
            onChange={(event) => {
              onInputChange(event.target.value, 'nume');
            }}
            className="form__input"
            required
          />
          {hasError(errors, 'nume', '1') && (
            <p className="form__input-error">{t('contact.form.name_required_error')}</p>
          )}
        </div>

        <div className="form__input-wrapper">
          <p className="form__input-label">{t('contact.form.school')}</p>
          <Select
            name="scoalaID"
            isSearchable
            value={selectValue}
            onChangeOptions={(option) => {
              setSelectValue(option);
              onInputChange(option.value, 'scoalaID');
            }}
            onInputValueChange={handleSearchCallback}
            className="form__select"
            options={[{ label: t('contact.form.another-school'), value: '0' }, ...schools]}
            noOptionsMessage={() =>
              searchIsLoading
                ? t('contact.form.school_loading')
                : t('contact.form.school_no_options')
            }
            noClientFilter
            placeholder={t('contact.form.placeholder')}
          />
          {(hasError(errors, 'scoalaID', '1') || hasError(errors, 'scoalaID', '19')) && (
            <p className="form__input-error">{t('contact.form.school_required_error')}</p>
          )}
          {hasError(errors, 'scoalaID', '100') && (
            <p className="form__input-error">{t('contact.form.school_invalid_error')}</p>
          )}
        </div>

        <div className="form__input-wrapper">
          <p className="form__input-label">{t('contact.form.phone')}</p>
          <PhoneInput
            phone={data?.telefon}
            onChange={(phone) => {
              onInputChange(phone, 'telefon');
            }}
          />
          {hasError(errors, 'telefon', '1') && (
            <p className="form__input-error">{t('contact.form.phone_required_error')}</p>
          )}
          {hasError(errors, 'telefon', '27') && (
            <p className="form__input-error">{t('contact.form.phone_invalid_format_error')}</p>
          )}
        </div>

        <div className="form__input-wrapper">
          <p className="form__input-label">{t('contact.form.email')}</p>
          <input
            name="email"
            type="text"
            value={data?.email}
            onChange={(event) => {
              onInputChange(event.target.value, 'email');
            }}
            className="form__input"
            required
          />
          {hasError(errors, 'email', '1') && (
            <p className="form__input-error">{t('contact.form.email_required_error')}</p>
          )}
          {hasError(errors, 'email', '3') && (
            <p className="form__input-error">{t('contact.form.email_invalid_format_error')}</p>
          )}
        </div>
        <div className="form__input-wrapper">
          <p className="form__input-label">{t('contact.form.message')}</p>
          <textarea
            name="mesaj"
            value={data?.mesaj}
            onChange={(event) => {
              onInputChange(event.target.value, 'mesaj');
            }}
            className="form__textarea"
            required
          />
          {hasError(errors, 'mesaj', '1') && (
            <p className="form__input-error">{t('contact.form.message_required_error')}</p>
          )}
          {hasError(errors, 'general', '429') && (
            <p className="form__input-error">{t('contact.form.general_too_many_requests_error')}</p>
          )}
          {showErrorMessage && <p className="form__input-error">{t('contact.form.api_error')}</p>}
        </div>
        <Button
          type="submit"
          className="form__button"
          onClick={submitForm}
          loading={isLoading}
          disabled={!Object.values(data).find((el) => el)}
        >
          {t('contact.form.send')}
        </Button>
      </form>
    </section>
  );
};

export default Form;
