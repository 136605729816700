import "./solutions.css";
import QuestionMarks from "../../assets/schools/solutions/11-question-marks.svg";

import { Button } from "../general";
import SolutionBox from "./SolutionBox";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
const Solutions = ({ type }) => {
  const { i18n, t } = useTranslation("common");

  return (
    <section className="solutions">
      <img src={QuestionMarks} alt={QuestionMarks} />
      <div className="solutions_container">
        <h1>{t("schools.solutions.title")}</h1>
        <SolutionBox
          title={t("schools.solutions.box1.title")}
          text={t("schools.solutions.box1.p")}
          open={!isMobile}
        />
        {type !== "virtual" && (
          <SolutionBox
            title={t("schools.solutions.box2.title")}
            text={t("schools.solutions.box2.p")}
            open={false}
          />
        )}
        <SolutionBox
          title={t("schools.solutions.box3.title")}
          text={t("schools.solutions.box3.p")}
          open={false}
        />
        {type !== "private" && type !== "chain" && (
          <SolutionBox
            title={t("schools.solutions.box4.title")}
            text={t("schools.solutions.box4.p")}
            moreContent={true}
            p2={t("schools.solutions.box4.p2")}
            p3={t("schools.solutions.box4.p3")}
            p4={t("schools.solutions.box4.p4")}
            p5={t("schools.solutions.box4.p5")}
            open={false}
          />
        )}
        <Button
          text={t("schools.solutions.btn")}
          type={"primary"}
          linkType={"intern"}
          link={`/${i18n.language}/p/contact`}
        />
      </div>
    </section>
  );
};

export default Solutions;
