import loginIcon from '../../../assets/general/header/key.svg';
import loginIcon2 from '../../../assets/general/header/key-hover.svg';
import LanguageSelector from '../footer/LanguageSelector';

import Logo from './Logo';
import Nav from './Nav';

import { useTranslation } from 'react-i18next';

import './header.css';
import './headerButtons.css';
import { Button2 } from '../Button';

export const Header = ({ activeEl, subMenuEl, flags }) => {
  const { t, i18n } = useTranslation('common');
  let currentLanguage = i18n.language;

  const toggleMenuItems = () => {
    if (window.innerWidth <= 860) {
      const navBar = document.getElementsByTagName('nav')[0];
      const currentState = navBar.style.display;
      switch (currentState) {
        case '':
        case 'none':
          navBar.style.display = 'flex';
          break;
        case 'flex':
          navBar.style.display = 'none';
          break;
        default:
          break;
      }
      document.getElementById('nav-icon').classList.toggle('open');
    }
  };
  return (
    <header>
      <Logo active={activeEl === 'home'} />

      <Nav activeEl={activeEl} closeMenu={toggleMenuItems} subMenuEl={subMenuEl} flags={flags} />

      <div className="authContainer">
        <a href={`/${currentLanguage}/auth`}>
          <Button2
            leftIcon={loginIcon}
            leftIconHover={loginIcon2}
            className={'outlined secondary'}
            allowDefault={true}
          >
            {t('header.login')}
          </Button2>
        </a>

        <LanguageSelector flags={flags} color="dark" sizeSmall />
      </div>

      <button id="hamburgerMenuBtn" onClick={toggleMenuItems}>
        <div id="nav-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
    </header>
  );
};

export default Header;
