import { Button } from "../general";
import styled from "styled-components";

import bgImg from "../../assets/about/Path 1272.svg";
import { useTranslation } from "react-i18next";

const BecomePartener = ({ action }) => {
  const { i18n, t } = useTranslation("common");
  return (
    <Container className="become_partener">
      <h3>{t("about.becomePartener.title")}</h3>
      <p>{t("about.becomePartener.p")}</p>
      <Button
        text={t("about.becomePartener.btn")}
        type={"ghost"}
        action={action ? action : null}
        linkType={"intern"}
        link={`/${i18n.language}/p/contact`}
      />
    </Container>
  );
};

export default BecomePartener;
const Container = styled.section`
  height: 295.5px;
  width: 590.2px;
  background-image: url("${bgImg}");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;

  & h3 {
    font-family: "Manrope";
    font-size: 34px;
    font-weight: bold;
    line-height: 1.76;
    text-align: left;
    color: var(--tertiary-ultralight);
  }
  & p {
    font-family: "Manrope";
    font-size: 20px;
    line-height: 1.27;
    text-align: left;
    color: var(--tertiary-ultralight);
  }
  & button {
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 1.2;
    width: 155px;
    height: 40px;
    margin-bottom: 50.3px;
    margin-top: 27.3px;
  }

  @media only screen and (max-width: 590.2px) {
    width: 100%;

    margin-bottom: 0 !important;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
    align-items: center;

    & h3 {
      font-size: 20px;
      line-height: 1.85;
    }
    & p {
      max-width: 190px;
      font-size: 14px;
      line-height: 1.57;
      text-align: center;
    }
    & button {
      margin-bottom: 24px;
      margin-top: 15.6px;
    }
  }
  @media only screen and (max-width: 590.2px) {
    width: 100%;
    height: calc(0.50067773636 * 100vw);
    margin-bottom: -10px;
    justify-content: center;
    align-items: center;

    & h3 {
      font-size: 20px;
      line-height: 1.85;
    }
    & p {
      max-width: 190px;
      font-size: 14px;
      line-height: 1.57;
      text-align: center;
    }
    & button {
      margin-bottom: 24px;
      margin-top: 15.6px;
    }
  }
  @media only screen and (max-width: 460.2px) {
    justify-content: flex-end;
  }
`;
