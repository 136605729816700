import cityHall from "../../assets/home/institutions/cityhall-icon.svg";
import femaleParents from "../../assets/home/adsBenefits/female-parent-icon.svg";
import headmaster from "../../assets/home/adsBenefits/headmaster-icon.svg";
import maleParent from "../../assets/home/adsBenefits/male-parent-icon.svg";
import teacher from "../../assets/home/adsBenefits/teacher-icon.svg";
import secretary from "../../assets/schools/icons/secretary-icon.svg";
import cercles from "../../assets/registerFlow/Cercles.svg";
import circle from "../../assets/registerFlow/Circle.svg";
import squares from "../../assets/registerFlow/squares.svg";
import triangle from "../../assets/registerFlow/Triangle.svg";
import { Button } from "../general";
import "./registerBenefits.css";
import "./userManager.css";
import { useTranslation } from "react-i18next";
import Typist from "react-typist";
import { useState, useEffect } from "react";
import { scrollInCenter } from "../../utility/horizontallScroll";
const SelectUser = ({ setUser }) => {
  const { i18n, t } = useTranslation("common");
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    if (currentUser && window.innerWidth <= 900) {
      scrollInCenter(
        document.getElementById("users"),
        document.getElementById("user-" + currentUser)
      );
    } else {
      if (window.innerWidth <= 900 && currentUser === undefined) {
        setCurrentUser("parent");
      }
    }
  }, [currentUser]);
  const handleClick = (role) => {
    window.history.replaceState(null, null, `?user=${role}`);
    if (window.innerWidth <= 900) {
      setCurrentUser(role);
    } else {
      setUser(role);
    }
  };
  return (
    <div id="selectUser">
      <h2>{t("register.userManager.title")}</h2>
      <p>
        {t("register.userManager.subTitle.1")} <br />
        {t("register.userManager.subTitle.2")}
      </p>
      <div className="userManager">
        <Typist cursor={{ show: false }} avgTypingDelay={35}>
          {t("register.userManager.typing")}
        </Typist>
        <img src={circle} alt={circle} />
        <div className="users" id="users">
          <div
            className="user"
            onClick={() => handleClick("parent")}
            id="user-parent"
          >
            <img src={maleParent} alt={maleParent} />
            <img src={femaleParents} alt={femaleParents} />
            <span>{t("register.roles.parent.role")}</span>
            <img
              src={cercles}
              alt={cercles}
              className={
                window.innerWidth <= 900 && currentUser === "parent"
                  ? "active"
                  : ""
              }
            />
          </div>
          <div
            className="user"
            onClick={() => handleClick("teacher")}
            id="user-teacher"
          >
            <img src={teacher} alt={teacher} />
            <span>{t("register.roles.teacher.role")}</span>
            <img
              src={cercles}
              alt={cercles}
              className={
                window.innerWidth <= 900 && currentUser === "teacher"
                  ? "active"
                  : ""
              }
            />
          </div>
          <div
            className="user"
            onClick={() => handleClick("secretary")}
            id="user-secretary"
          >
            <img src={secretary} alt={secretary} />
            <span>{t("register.roles.secretary.role")}</span>
            <img
              src={cercles}
              alt={cercles}
              className={
                window.innerWidth <= 900 && currentUser === "secretary"
                  ? "active"
                  : ""
              }
            />
          </div>
          <div
            className="user"
            onClick={() => handleClick("headmaster")}
            id="user-headmaster"
          >
            <img src={headmaster} alt={headmaster} />
            <span>{t("register.roles.headmaster.role")}</span>
            <img
              src={cercles}
              alt={cercles}
              className={
                window.innerWidth <= 900 && currentUser === "headmaster"
                  ? "active"
                  : ""
              }
            />
          </div>
          <div
            className="user"
            onClick={() => handleClick("cityhall")}
            id="user-cityhall"
          >
            <img src={cityHall} alt={cityHall} />
            <span>{t("register.roles.cityhall.role")}</span>
            <img
              src={cercles}
              alt={cercles}
              className={
                window.innerWidth <= 900 && currentUser === "cityhall"
                  ? "active"
                  : ""
              }
            />
          </div>
        </div>
        <Button
          text={t("register.userManager.btn")}
          type={"blue-btn"}
          action={() => {
            setUser(currentUser);
          }}
        />
      </div>
      <div className="registerBenefits">
        <img src={triangle} alt={triangle} />
        <h3>{t("register.registerBenefits.title")}</h3>
        <div className="benefitsContainer">
          <div className="benefits">
            <div className="benefit">
              <span>1.</span>
              <p>{t("register.registerBenefits.benefits.1")}</p>
            </div>
            <div className="benefit">
              <span>2.</span>
              <p>{t("register.registerBenefits.benefits.2")}</p>
            </div>
            <div className="benefit">
              <span>3.</span>
              <p>{t("register.registerBenefits.benefits.3")}</p>
            </div>
          </div>
          <Button
            text={t("register.registerBenefits.btn")}
            type={"secodaryHeavy-invert"}
            linkType={"intern"}
            link={`/${i18n.language}/p/contact`}
          />
        </div>
        <img src={squares} alt={squares} />
      </div>
    </div>
  );
};

export default SelectUser;
