import "./parents_recommend.css";
import { Button } from "../general";
import Star_1 from "../../assets/parents/star_1.svg";
import Star_2 from "../../assets/parents/star_2.svg";
import { useEffect } from "react";
import AOS from "aos";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const openModal = async () => {
  const shareData = {
    title: "adservio",
    text: "adservio text",
    url: "https://adservio.ro",
  };

  if (isMobile) {
    navigator
      .share(shareData)
      .then(() => console.log("Share was successful."))
      .catch((error) => {
        console.log("Sharing failed", error);
      });
  } else {
    console.log(`Your system doesn't support sharing files.`);
    document.getElementById("shareModal").style.display = "block";
  }
};
const ParentsRecommend = () => {
  const { t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <section className="parents_recommend">
      <div className="parents_recommend_container">
        <h1>{t("parents.recommend.title")}</h1>
        <div>
          <h2>{t("parents.recommend.subtitle.1")}</h2>
          <h2>{t("parents.recommend.subtitle.2")}</h2>
        </div>
        <p>{t("parents.recommend.text")}</p>
        <Button text={t("parents.recommend.button")} action={openModal} />
        <img src={Star_1} alt={Star_1} data-aos="fade-up-right" />
        <img src={Star_2} alt={Star_2} data-aos="fade-down-left" />
      </div>
    </section>
  );
};
export default ParentsRecommend;
