import React from 'react';

import './BubblesSection.css';

import coins from '../../../assets/general/coins.svg';
import statistics from '../../../assets/general/pie-chart.svg';
import grid from '../../../assets/general/grid.svg';
import comment from '../../../assets/general/comment.svg';
import yoga from '../../../assets/general/yoga.svg';

import { useTranslation } from 'react-i18next';

const BubblesSection = () => {
  const { t } = useTranslation('common');
  return (
    <section className="bubbles_section">
      <div className="bubbles_container">
        <div>
          <img src={coins} alt="coins" />
          <p>{t('general.bubbles_section.billing')}</p>
        </div>
        <div>
          <img src={yoga} alt="wellbeing" />
          <p>{t('general.bubbles_section.implication')}</p>
        </div>
        <div className="bubbles-primary">
          <img src={grid} alt="grid" />
          <p>{t('general.bubbles_section.management')}</p>
        </div>
        <div className="bubbles-reverse">
          <img src={statistics} alt="statistics" />
          <p>{t('general.bubbles_section.statistics')}</p>
        </div>
        <div className="bubbles-reverse">
          <img src={comment} alt="comment" />
          <p>{t('general.bubbles_section.communication')}</p>
        </div>
      </div>
    </section>
  );
};

export default BubblesSection;
