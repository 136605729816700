import "./introSchools.css";
import "./introVirtual.css";
import IntroVirtualBackground from "../../../assets/schools/intro/schools-virtual-intro-background.svg";
import { Button } from "../../general";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
const IntroVirtual = ({calculatorRef,virtualRef}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "false", duration: 750, offset: -750 });
  }, []);
  const scrollToCalculator = () => {
    if (calculatorRef.current) {
      const calculatorTopOffset = calculatorRef.current.offsetTop;
      const windowHeight = window.innerHeight;
      const calculatorHeight = calculatorRef.current.offsetHeight;

      const targetScrollPosition = calculatorTopOffset - windowHeight / 2 + calculatorHeight / 2;

      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    }
  };
  return (
    <div className="intro_schools" id="intro_virtual">
      <img
        src={IntroVirtualBackground}
        alt={IntroVirtualBackground}
        data-aos="fade-left"
      />
      <div className="intro_container">
        <h1>{t("schools.intro.virtual.title")}</h1>
        <p>{t("schools.intro.virtual.p1")}</p>
        <p>{t("schools.intro.virtual.p2")}</p>
        <div className="intro_buttons_container">
          <div>
            <Button
              text={t("home.intro.content.buttons.btn1")}
              type={"primary"}
              action={scrollToCalculator}
            />
          </div>
          <p
            className="intro_packages-link"
            onClick={() => {
              navigate(`/${i18n.language}/p/subscriptions?user=school`);
            }}
          >
            {t("schools.intro.packagesLink")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntroVirtual;
