import bgShapes from "../../assets/general/calculator/bg-shapes.svg";
import check from "../../assets/general/calculator/check.svg";

import { useState, useEffect } from "react";
import AOS from "aos";
import { useTranslation, Trans } from "react-i18next";
import { Button, Button2 } from "./Button";
import PhoneInput from "../forms/PhoneInputWithSelect";

import Api2 from "../../Libs/api";
import "./calculator.css";
import { useNavigate } from "react-router-dom";

export const Calculator = ({ calculatorRef }) => {
  const { t, i18n } = useTranslation("common");
  const [offer, setOffer] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    school_type: "",
    phone: "",
    email: "",
    students_no: 0,
    _lang: i18n.language,
  });

  useEffect(() => {
    AOS.init({ offset: 350 });
  }, []);

  const navigate = useNavigate();
  const hasError = (formErrors, fieldName, errorCode) => {
    if (!formErrors.length) return false;
    const error = formErrors.filter(
      (fieldErr) =>
        fieldErr.errorField === fieldName && fieldErr.errorCode === errorCode
    );
    return error.length ? error[0] : false;
  };

  const onInputChange = (value, input) => {
    if (input === "phone") value = value.replace(/\s/g, "");
    if (input === "students_no") {
      value = parseInt(value.split('+')[0], 10)
      value = value > 1500 ? 1500 : isNaN(value) ? 0 : value
    }
    setData({
      ...data,
      [input]: input === "students_no" ? value : value,
    });
  };

  const handleError = (err) => {
    if (err && err.response && typeof err.response.data !== "string") {
      const apiErrors = err.response.data;
      setErrors(apiErrors);
    }
  };

  const handleOffer = () => {
    Api2.post("/generic/request_offer", data)
      .then((resp) => {
        if (resp.result) {
          setErrors([]);
          setOffer(!offer);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const initalOfferRequest = () => {
    setData({
      school_type: "",
      phone: "",
      email: "",
      students_no: 0,
      _lang: i18n.language,
    });
    setOffer(false);
  };

  return (
    <section className="calculator" ref={calculatorRef}>
      <div className="background_stars">
        <img
          src={bgShapes}
          alt={bgShapes}
          data-aos="fade-in"
          data-aos-duration="1000"
        />
      </div>
      <h1>{t("general.calculator.title")}</h1>
      <div className="horizontal-banner">
        <div>
          <h3>
            <Trans
              i18nKey="general.calculator.title2-new"
              t={t}
              components={[<br />]}
            />
          </h3>
        </div>
      </div>
      <div className="calculator_container">
        {offer && (
          <div className="offerCheck">
            <img src={check} alt={check} />
            <h2>
              <Trans
                i18nKey="general.calculator.offerSuccess"
                t={t}
                components={[<br />]}
              />
            </h2>
            <Button
              text={"OK"}
              type="secodaryHeavy"
              action={() => initalOfferRequest()}
            />
          </div>
        )}
        {!offer && (
          <>
            <h3>{t("general.calculator.content.newTitle")}</h3>
            <form autoComplete="off">
              <div className="range-selector">
                <label htmlFor="pupils">
                  {t("general.calculator.content.nrOfPupils")}
                </label>
                <input
                  type="range"
                  value={isNaN(data.students_no) ? 0 : data.students_no}
                  min="0"
                  max="1500"
                  name="students_no"
                  id="pupils"
                  onInput={(event) => {
                    onInputChange(event.target.value, "students_no");
                  }}
                />
                <input
                  type="text"
                  inputmode="numeric"
                  name="students_no"
                  id="pupilsRangevalue"
                  value={data.students_no >= 1500
                    ? "1500+"
                    : isNaN(data.students_no)
                      ? ''
                      : data.students_no
                  }
                  min="0"
                  max="1500"
                  onInput={(event) => {
                    onInputChange(event.target.value, "students_no");
                  }}
                />
              </div>
              {hasError(errors, "students_no", "1") && (
                <span className="form__input-error">
                  {t("general.calculator.content.error.studentsNo")}
                </span>
              )}

              <p>{t("general.calculator.content.schoolType.label")}</p>
              <div className="school-type">
                <label htmlFor="private-school">
                  <input
                    type="radio"
                    name="school_type"
                    id="private-school"
                    onChange={() => {
                      setData({ ...data, school_type: 1 });
                    }}
                    value="1"
                  />
                  <span>{t("general.calculator.content.schoolType.opt1")}</span>
                </label>
                <label htmlFor="public-school">
                  <input
                    type="radio"
                    name="school_type"
                    id="public-school"
                    onChange={() => {
                      setData({ ...data, school_type: 2 });
                    }}
                    value="2"
                  />
                  <span>{t("general.calculator.content.schoolType.opt2")}</span>
                </label>
                <label htmlFor="virtual-school">
                  <input
                    type="radio"
                    name="school_type"
                    id="virtual-school"
                    onChange={() => {
                      setData({ ...data, school_type: 3 });
                    }}
                    value="3"
                  />
                  <span>{t("general.calculator.content.schoolType.opt3")}</span>
                </label>
              </div>
              {hasError(errors, "school_type", "1") && (
                <span className="form__input-error">
                  {t("general.calculator.content.error.schoolType")}
                </span>
              )}

              <div className="offer-contact">
                <div className="offer-contact-email">
                  <label htmlFor="email">
                    {t("general.calculator.content.emailText")}
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="nope"
                    value={data?.email}
                    onChange={(event) => {
                      onInputChange(event.target.value, "email");
                    }}
                  />

                  {hasError(errors, "email", "1") && (
                    <span className="form__input-error">
                      {t("general.calculator.content.error.email")}
                    </span>
                  )}
                  {hasError(errors, "email", "3") && (
                    <span className="form__input-error">
                      {t("general.calculator.content.error.emailNoValid")}
                    </span>
                  )}
                </div>
                <div className="offer-contact-phone">
                  <label htmlFor="phone">
                    {t("general.calculator.content.phoneText")}
                  </label>
                  <PhoneInput
                    phone={data?.phone}
                    onChange={(phone) => {
                      onInputChange(phone, "phone");
                    }}
                  />

                  {hasError(errors, "phone", "1") && (
                    <span className="form__input-error">
                      {t("general.calculator.content.error.phone")}
                    </span>
                  )}
                </div>
              </div>
              <Button2
                className="secondary solid"
                handleClick={() => handleOffer()}
              >
                {t("general.calculator.newBtn")}
              </Button2>
            </form>
            <p className="or">sau</p>
            <div className="calculator_packages-link">
              <Button2
                className="ghost dark "
                handleClick={() => {
                  navigate(`/${i18n.language}/p/subscriptions`);
                }}
              >

                {t("general.calculator.packagesBtn")}

              </Button2>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Calculator;
