import ReactHtmlParser from 'react-html-parser';

import {  Button2 } from "../general";
import shapes from "../../assets/home/modernLearning/shapes.svg";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./modernLearning.css";

const ModernLearning = () => {
  const { i18n, t } = useTranslation("common");

  const navigate = useNavigate();

  return (
    <section id="container_benefits">
    
      <section className="beefits_content">
        <img src={shapes} alt={shapes} />
        <section className="textContainer">
        <h1>{t("home.modernLearning.title_2")}</h1>
          <div className="benefits">
            <div className="benefit">
              <span>1.</span>
              <section>
                <h4>{t("home.modernLearning.content.subTitle4")}</h4>
                <p>{ReactHtmlParser(t("home.modernLearning.content.p2").split('.').join('<br />'))}</p>
              </section>
            </div>
            <div className="benefit">
              <span>2.</span>
              <section>
                <h4>{t("home.modernLearning.content.subTitle5")}</h4>
                <p>{
                  ReactHtmlParser(t("home.modernLearning.content.p3").split('.').join('<br />'))}</p>
              </section>
            </div>
            <div className="benefit">
              <span>3.</span>
              <section>
                <h4>{t("home.modernLearning.content.subTitle6")}</h4>
                <p>{ReactHtmlParser(t("home.modernLearning.content.p4"))}</p>
              </section>
            </div>
          </div>
          <Button2
            className={"ghost"}
            handleClick={() => navigate(`/${i18n.language}/p/schools/public`)}
          >
            {t("home.modernLearning.content.btn")}
          </Button2>
        </section>
      </section>
    </section>
  );
};

export default ModernLearning;
