import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import PhoneInput, { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input';

import CountrySelect from './CountrySelect';

import 'react-phone-number-input/style.css';

import './PhoneInputWithSelect.css';
import useCheckHostname from '../../../utility/helper';

const PhoneInputWrapper = (props) => {
  const { phone, label, disabled, onChange, ...otherProps } = props;
  const [phoneExtension, setPhoneExtension] = useState(null);
  const isEducation = useCheckHostname('education');

  useEffect(() => {
    let localPhoneExtension;

    const location = window.location.host.split('.')[2];
    switch (location) {
      case 'com':
        localPhoneExtension = 'GB';
        break;
      case 'md':
        localPhoneExtension = 'MD';
        break;
      case 'hu':
        localPhoneExtension = 'HU';
        break;
      default:
        localPhoneExtension = 'RO';
        break;
    }

    setPhoneExtension(isEducation ? 'GB' : localPhoneExtension);
  }, [isEducation]);

  const handleChange = (phoneNumber) => {
    onChange(formatPhoneNumberIntl(phoneNumber));
    if (phoneNumber && parsePhoneNumber(phoneNumber)) {
      setPhoneExtension(parsePhoneNumber(phoneNumber).country);
    }
  };

  return (
    <div className="comp__phone-input-with-select">
      <div className="label">{label}</div>
      <div className="wrapper">
        <PhoneInput
          defaultCountry={phoneExtension}
          value={phone}
          disabled={disabled}
          onChange={handleChange}
          countrySelectComponent={() => {
            return (
              <CountrySelect
                value={phoneExtension}
                disabled={disabled}
                onChange={(countryValue) => {
                  setPhoneExtension(countryValue);
                  onChange('');
                }}
              />
            );
          }}
          {...otherProps}
        />
      </div>
    </div>
  );
};

PhoneInputWrapper.propTypes = {
  phone: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

PhoneInputWrapper.defaultProps = {
  phone: '',
  label: '',
  disabled: false,
  onChange: () => {},
};

export default PhoneInputWrapper;
