import "./cityhall_digitalization.css";
import { Button } from "../general";
import CityhallBuzauLogo from "../../assets/institutions/cityhall_buzau_logo.svg";
import ConstantinToma from "../../assets/Testimoniale Homepage/Constantin-Toma.png";
import { useTranslation } from "react-i18next";
const CityhallDigitalization = () => {
  const { i18n, t } = useTranslation("common");
  return (
    <section className="cityhall_digitalization">
      <img src={CityhallBuzauLogo} alt={CityhallBuzauLogo} />
      <div className="study_case">
        <p>{t("institutions.cityhall.digitalization.p")}</p>
      </div>
      <h1>{t("institutions.cityhall.digitalization.title")}</h1>
      <div className="cityhall_digitalization_cards">
        <div className="cityhall_digitalization_card">
          <h2>{t("institutions.cityhall.digitalization.card1.h2")}</h2>
          <p>{t("institutions.cityhall.digitalization.card1.p1")}</p>
          <p>{t("institutions.cityhall.digitalization.card1.p2")}</p>
        </div>
        <div className="cityhall_digitalization_card">
          <h2>{t("institutions.cityhall.digitalization.card2.h2")}</h2>
          <p>{t("institutions.cityhall.digitalization.card2.p1")}</p>
          <p>{t("institutions.cityhall.digitalization.card2.p2")}</p>
        </div>
        <div className="cityhall_digitalization_card">
          <h2>{t("institutions.cityhall.digitalization.card3.h2")}</h2>
          <p>{t("institutions.cityhall.digitalization.card3.p1")}</p>
          <p>{t("institutions.cityhall.digitalization.card3.p2")}</p>
        </div>
        <div className="cityhall_digitalization_card">
          <p>
            {t("institutions.cityhall.digitalization.card4.p.t1")}
            <br />
            <br />
            {t("institutions.cityhall.digitalization.card4.p.t2")}
          </p>
          <h2>{t("institutions.cityhall.digitalization.card4.h2")}</h2>
          <h3>{t("institutions.cityhall.digitalization.card4.h3")}</h3>
          <img src={ConstantinToma} alt={ConstantinToma} />
        </div>
      </div>
      <Button
        text={t("institutions.cityhall.digitalization.button")}
        type={"primary"}
        linkType={"intern"}
        link={`/${i18n.language}/p/contact`}
      />
    </section>
  );
};

export default CityhallDigitalization;
