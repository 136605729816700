import "./ministry_intro.css";
import { Button } from "../general";
import MinistryIntroBackground from "../../assets/institutions/ministry_intro_background.svg";
import { useEffect } from "react";
import AOS from "aos";
import { useTranslation } from "react-i18next";
const MinistryIntro = () => {
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  const { i18n, t } = useTranslation("common");

  return (
    <section className="ministry_intro">
      <div className="ministry_intro_content">
        <img
          src={MinistryIntroBackground}
          alt={MinistryIntroBackground}
          data-aos="fade-left"
        />
        <h1>{t("institutions.ministry.intro.title")}</h1>
        <div className="ministry_intro_paragraphs">
          <div className="ministry_intro_parahraph_1">
            <p>{t("institutions.ministry.intro.p1")}</p>
            <p>{t("institutions.ministry.intro.p2")}</p>
          </div>
          <p>{t("institutions.ministry.intro.p3")}</p>
        </div>
        <h3>
          {t("institutions.ministry.intro.h3.t1")}
          <br />
          {t("institutions.ministry.intro.h3.t2")}
        </h3>
        <Button
          text={t("institutions.ministry.intro.button")}
          type={"primary"}
          linkType={"intern"}
          link={`/${i18n.language}/p/contact`}
        />
      </div>
    </section>
  );
};

export default MinistryIntro;
