import { Header } from '../general/header/index.js';
import Intro from './Intro';
import VideoModal from '../general/VideoModal.js';
import ModernLearning from './ModernLearning.js';
import {Newsletter} from './Newsletter';
import AdservioBenefits from './AdservioBenefits.js';
import Recommendations from './Recommendations.js';
import Reasons from './Reasons.js';
import Institutions from './Institutions.js';
import Parteners from './Parteners.js';
import {Calculator} from '../general/Calculator';
import Mentions from './Mentions.js';
import Footer from '../general/footer/Footer.js';
import ShareModal from '../general/ShareModal.js';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import MechanicalCounters from '../general/MechanicalCounters.js';
import { Helmet } from 'react-helmet';
import Api2 from '../../Libs/api.js';
import BubblesSection from '../general/BubblesSection/BubblesSection.js';
import useCheckHostname from '../../utility/helper.js';

const Home = () => {
  const introRef = useRef(null);
  const calculatorRef = useRef(null);
  const isEducation = useCheckHostname('education');

  let { lng } = useParams();
  const { i18n, t } = useTranslation('common');
  const [flags, setFlags] = useState();

  const navigate = useNavigate();

  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };

  
  useEffect(() => {
    if (!lng) {
      if (isEducation) {
        changeLanguage('en');
        navigate('/en');
      } else {
        navigate('/' + i18n.language);
      }
    } else if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('general.keysForMeta.home.title')}</title>
        <meta name="description" content={t('general.keysForMeta.home.description')} />
        <meta name="keywords" content={t('general.keysForMeta.home.keywords')} />
        <meta property="og:title" content={t('general.keysForMeta.home.title')} />
        <meta property="og:description" content={t('general.keysForMeta.home.description')} />
      </Helmet>

      <ShareModal />

      {videoUrl && <VideoModal videoUrl={videoUrl} setVideoUrl={setVideoUrl} />}

      <Header flags={flags} activeEl={'home'} />

      <main>
        <Intro introRef={introRef} calculatorRef={calculatorRef} />
        <MechanicalCounters />
        <BubblesSection />
        <ModernLearning />
        <AdservioBenefits />
        <Reasons />
        <Recommendations videoUrl={videoUrl} setVideoUrl={setVideoUrl} />
        <Institutions />
        <Newsletter />
        <Parteners type={'homePage'} title={t('home.parteners.title')} />
        <Calculator calculatorRef={calculatorRef} />
        <Mentions />
      </main>

      <Footer flags={flags} />
    </>
  );
};

export default Home;
