import { Button } from "../general";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ValuesBanner = () => {
  const { i18n, t } = useTranslation("common");
  return (
    <Container className="values_banner">
      <h1>{t("about.valuesBanner.title")}</h1>
      <h3>{t("about.valuesBanner.subTitle")}</h3>
      <Button
        text={t("about.valuesBanner.btn")}
        type={"primary"}
        linkType={"intern"}
        link={`/${i18n.language}/p/register`}
      />
    </Container>
  );
};

export default ValuesBanner;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--main-default);
  height: fit-content;
  max-width: 1442px;
  width: calc(100vw - 100px);

  margin-left: auto;
  margin-right: auto;
  margin-top: 59px;
  border-radius: 9px;
  padding: 0 49px;
  & h1,
  & h3 {
    text-align: center;
    font-family: "Manrope";
    font-size: 34px;
    line-height: 1.2;
    color: var(--tertiary-ultralight);
  }
  & h1 {
    font-weight: bold;
    margin-top: 35px;
    margin-bottom: 5px;
  }
  & button {
    font-family: "Open Sans";
    margin-top: 29.4px;
    margin-bottom: 34.6px;
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
  }
  @media only screen and (max-width: 1020px) {
    & h1 {
      font-size: 26px;
      font-weight: bold;

      line-height: 1.23;
      margin-top: 31.3px;
      margin-bottom: 25px;
      text-align: center;
      color: var(--tertiary-ultralight);
    }
    & h3 {
      margin: 0;
      font-size: 16px;

      line-height: 1.38;

      text-align: center;
      color: var(--tertiary-ultralight);
    }
    & button {
      line-height: 1.2;
      font-size: 16px;
      margin-top: 30.1px;
      margin-bottom: 24.5px;
    }
  }
  @media only screen and (max-width: 550px) {
    & button {
      margin-top: 47.1px;
      margin-bottom: 41.5px;
    }
  }
`;

/*



*/
