import { Button } from "../general";
import VideoContainer from "../schools/VideoContainer";
import schoolIcon from "../../assets/registerFlow/schoolIcon.svg";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import { useEffect } from "react";
const RegisterError = ({ user, openModal }) => {
  const { i18n, t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 750 });
  }, []);

  let link = '';
  switch (user)
  {
    case 'parent': link = 'parents'; break;
    case 'cityhall': link = 'institutions/cityhall'; break;
    case 'student': link = 'students'; break;
    case 'teacher': link = 'schools/public?for=teacher'; break;
    default: break;
  }

  return (
    <div id="registerError">
      <img src={schoolIcon} alt={schoolIcon} data-aos="fade-up" />
      <h1>
        {t("register.registerError.title.1")}
        <br />
        {t("register.registerError.title.2")}
        {t(`register.roles.${user}.role`)}
      </h1>
      <div>
        <section>
          <h3> {t("register.registerError.section1.title")}</h3>
          <Button
            text={t("register.registerError.section1.btn")}
            type={"primary"}
            action={openModal}
          />
        </section>
        {user !== "cityhall" && (
          <section className="registerVideo">
            <h3>
              {t("register.registerError.section2.title")}{" "}
              {t(`register.roles.${user}.role`)}
            </h3>
            <div className="videoContainer">
              <VideoContainer
                type={user === "student" || user === "parent" ? user : "public"}
                activeStaff={user}
              />
            </div>
          </section>
        )}
        <section>
          <h3> {t("register.registerError.section3.title")}</h3>
          <Button
            text={
              t("register.registerError.section3.btn") +
              t(`register.roles.${user}.plural`)
            }
            type={"blue-btn"}
            linkType={"intern"}
            link={`/${i18n.language}/p/${link}`}
          />
        </section>
      </div>
    </div>
  );
};

export default RegisterError;
