import { MechanicalCounter } from 'mechanical-counter';
import { useState, useEffect } from 'react';
import enterOnViewPort from '../../utility/positionOnViewPort';
import { useTranslation } from 'react-i18next';

import './mechanical_counters.css';

const MechanicalCounters = () => {
  const { t } = useTranslation('common');
  const [text1, set1] = useState('55');
  const [text2, set2] = useState('55');
  const [text3, set3] = useState('5555');
  const [text4, set4] = useState('999999');
  const [text5, set5] = useState('55555');
  const [key, setKey] = useState(0);

  const onSetNumber = () => {
    set1('16');
    set2('27');
    set3('1150');
    set4('1');
    set5('24300');
  };

  const reloadComponent = () => {
    setKey(prev => prev + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (enterOnViewPort(document.getElementsByClassName('mechanical_counters')[0], 0)) {
        onSetNumber();
        clearInterval(interval);
      }
    }, 0.05);

    window.addEventListener('resize', reloadComponent);
    return () => {
      window.removeEventListener('resize', reloadComponent);
    };
  }, []);

  const screenWidth = window.innerWidth;
  const counterHeight = screenWidth > 950 ? '79px' : '52px';

  return (
    <div key={key} className="mechanical_counters">
      <div className="mechanical_counters_wrapper">
        <div className="counter_container">
          <div className="counter">
            <MechanicalCounter text={text1 + t('general.counters.c1.years')} />
          </div>
          <p>{t('general.counters.c1.text')}</p>
        </div>
        <div className="counter_container">
          <div className="counter">
            <MechanicalCounter text={text2} />
          </div>
          <p>{t('general.counters.c2.text')}</p>
        </div>
        <div className="counter_container">
          <div className="counter">
            <MechanicalCounter text={text3} />
          </div>
          <p>{t('general.counters.c3.text')}</p>
        </div>
        <div className="counter_container">
          <div className="counter">
            <MechanicalCounter text={text4 + "mil."} height={counterHeight} />
          </div>
          <p>{t('general.counters.c4.text')}</p>
        </div>
        <div className="counter_container">
          <div className="counter">
            <MechanicalCounter text={text5} />
          </div>
          <p>{t('general.counters.c5.text')}</p>
        </div>
      </div>
    </div>
  );
};

export default MechanicalCounters;
