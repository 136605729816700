import desktopBackgroud from "../../assets/home/institutions/desktopBackground.svg";
import arches from "../../assets/home/institutions/gold-arches.svg";
import cityhall from "../../assets/home/institutions/cityhall-icon.svg";
import ministry from "../../assets/home/institutions/ministry-icon.svg";

import { useEffect } from "react";
import { Button2 } from "../general";
import AOS from "aos";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./institutions.css";
import "aos/dist/aos.css";

const Institutions = () => {
  const { i18n, t } = useTranslation("common");

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <section className="institution">
      <h1>{t("home.institution.title")}</h1>
      <div className="institution_container">
        <img data-aos="fade-up" src={arches} alt={arches} />
        <img src={desktopBackgroud} alt={desktopBackgroud} />
        <div className="buildings">
          <img src={arches} alt={arches} data-aos="fade-in" />
          <img src={cityhall} alt={cityhall} />
          <img src={ministry} alt={ministry} />
        </div>
        <div className="institution_info_1_container">
          
          <h2>{t("home.institution.content.section1.title-new")}</h2>
          <p>{t("home.institution.content.section1.p1")}</p>

          <Button2 
            className={"primary solid"}
            handleClick={() => navigate(`/${i18n.language}/p/institutions/cityhall`)}
          >
            {t("home.institution.content.section1.btn")}
          </Button2>
        </div>
        
        <div className="institution_info_2_container">
          <h2>{t("home.institution.content.section2.title")}</h2>
          <p>{t("home.institution.content.section2.p1")}</p>

          <Button2
            className={"primary solid"}
            handleClick={() => navigate(`/${i18n.language}/p/institutions/ministry`)}
          >
            {t("home.institution.content.section2.btn")}
          </Button2>
        </div>
      </div>
    </section>
  );
};

export default Institutions;
