import "./introSchools.css";
import "./introPrivate.css";
import IntroPrivateBackground from "../../../assets/schools/intro/schools-private-intro-background.svg";
import { Button } from "../../general";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
const IntroPrivate = ({introRef,calculatorRef}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "false", duration: 750, offset: -1050 });
  }, []);
  const scrollToCalculator = () => {
    if (calculatorRef.current) {
      const calculatorTopOffset = calculatorRef.current.offsetTop;
      const windowHeight = window.innerHeight;
      const calculatorHeight = calculatorRef.current.offsetHeight;

      const targetScrollPosition = calculatorTopOffset - windowHeight / 2 + calculatorHeight / 2;

      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    }
  };
  return (
    <div className="intro_schools" id="intro_private">
      <img
        src={IntroPrivateBackground}
        alt={IntroPrivateBackground}
        data-aos="fade-left"
      />
      <div className="intro_container">
        <h1>{t("schools.intro.private.title")}</h1>
        <div className="advantages">
          <div className="advantages_box">
            <h2>{t("schools.intro.private.advantage_1")}</h2>
            <p>{t("schools.intro.private.advantage_1_details")}</p>
          </div>
          <div className="advantages_box">
            <h2>{t("schools.intro.private.advantage_2")}</h2>
            <p>{t("schools.intro.private.advantage_2_details")}</p>
          </div>
          <div className="advantages_box">
            <h2>{t("schools.intro.private.advantage_3")}</h2>
            <p>{t("schools.intro.private.advantage_3_details")}</p>
          </div>
        </div>
        <div className="intro_buttons_container">
          <div>
            <Button
              text={t("home.intro.content.buttons.btn1")}
              type={"primary"}
              action={scrollToCalculator}
            />
          </div>
          <p
            className="intro_packages-link"
            onClick={() => {
              navigate(`/${i18n.language}/p/subscriptions?user=school`);
            }}
          >
            {t("schools.intro.packagesLink")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntroPrivate;
