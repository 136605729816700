import "./ministry_electronic.css";
import MinistryElectronicImg from "../../assets/institutions/ministry_electronic_catalogue.svg";
import { Button } from "../general";
import { useTranslation } from "react-i18next";

const MinistryElectronic = () => {
  const { i18n, t } = useTranslation("common");
  return (
    <section className="ministry_electronic">
      <div className="ministry_electronic_container">
        <img src={MinistryElectronicImg} alt={MinistryElectronicImg} />
        <div className="ministry_electronic_content">
          <h1>{t("institutions.ministry.electronic.title")}</h1>
          <p>{t("institutions.ministry.electronic.p1")}</p>
          <p>{t("institutions.ministry.electronic.p2")}</p>
          <Button
            text={t("institutions.ministry.electronic.button")}
            type={"primary"}
            linkType={"intern"}
            link={`/${i18n.language}/p/contact`}
          />
        </div>
      </div>
    </section>
  );
};

export default MinistryElectronic;
